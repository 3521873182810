<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.SheetStatus"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title" data-test="sel-role-header">
          <breadcrumb
            location="/sheet-status"
            text="Statut Affaire"
          ></breadcrumb>
          {{ sheetStatus.name }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li class="">
                <a>Informations</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns">
            <div class="column is-half" v-if="sheetStatus">
              <text-field
                label="Nom"
                v-model="sheetStatus.name"
                required
              ></text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "sheetStatus",
  props: ["id"],
  computed: {
    ...mapGetters({
      editMode: "App/isEdition",
    }),
  },
  data() {
    return {
      sheetStatus: {},
    };
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      axios.get(`/sheet-status/${this.id}`).then((r) => {
        this.sheetStatus = r.data;
        this.$store.dispatch("App/setEditing", false);
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/sheet-status/${this.id}`).then((res) => {
            if (res.data.success) {
              this.$router.replace("/sheet-status");
            }
          })
      );
    },
    save() {
      return axios
        .put(`/sheet-status/${this.id}`, this.sheetStatus)
        .then((response) => {
          if (response.data.success) {
            this.fetchData();
          }
        });
    },
    edit() {
      this.$store.dispatch("App/setEditing", true);
    },
    cancel() {
      this.fetchData();
    },
  },
};
</script>

<style lang='scss' scoped>
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}
</style>